<template>
  <div class="animation-test-wrapper">

  </div>
</template>

<script>
import * as THREE from 'three';

const _ = require('lodash');

export default {
  name: 'AnimationTestView',
  data () {
    return {
      scrollPercent: 0
    }
  },
  methods: {
    handleScroll () {
      const scrollTop = window.scrollY || this.$el.scrollTop;
      const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      this.scrollPercent = scrollTop / docHeight;
    },
    init () {
      const aspect = window.innerWidth / window.innerHeight;
      const fSize = 15;

      const scene = new THREE.Scene();
      // const camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );
      const camera = new THREE.OrthographicCamera(fSize * aspect / - 2, fSize * aspect / 2, fSize / 2, fSize / - 2, 1, 100);

      const geo = new THREE.BoxGeometry(6, 4, .1);
      const mat = new THREE.MeshBasicMaterial({color: 0x0ffff0});

      // const box1 = new THREE.Mesh(geo, mat);
      // const box2 = new THREE.Mesh(geo, mat);
      // const box3 = new THREE.Mesh(geo, mat);

      const box1 = new THREE.LineSegments(geo, mat);
      const box2 = new THREE.LineSegments(geo, mat);
      const box3 = new THREE.LineSegments(geo, mat);

      scene.add(box1, box2, box3);

      camera.position.z = 5;

      /**
       * scroll animation params
       */

      const elements = [box1, box2, box3]

      elements.forEach(el => {
        el.anim = {};
        el.anim.currentPos = [0, 0, 0];
        el.anim.currentRot = [0, 0, 0];
      });

      box1.anim.path = {
        0: [-4, 0, 0],
        .33: [-10, -20, 0],
        .66: [-10, -10, 0],
        1: [-10, 0, 0]
      }

      box1.anim.rot = {
        0: [0, 0, 0],
        .33: [0, 35, 0]
      }

      box2.anim.path = {
        0: [4, 0, 0],
        .33: [-10, 0, 0],
        .66: [-10, 10, 0],
        1: [-10, 20, 0]
      }

      box2.anim.rot = box1.anim.rot;

      box3.anim.path = {
        0: [0, 1, 0],
        .33: [-10, -10, 0],
        .66: [-10, 0, 0],
        1: [-10, 10, 0]
      }

      box3.anim.rot = box1.anim.rot;

      const renderer = new THREE.WebGLRenderer({alpha: true});
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.autoClear = true;

      const animate = () => {
        this.handleAnimation(elements, this.scrollPercent);

        elements.forEach(el => {
          el.position.x = el.currentPos[0];
          el.position.y = el.currentPos[1];
          el.position.z = el.currentPos[2];

          el.rotation.x = el.currentRot[0] * (Math.PI / 180);
          el.rotation.y = el.currentRot[1] * (Math.PI / 180);
          el.rotation.z = el.currentRot[2] * (Math.PI / 180);
        });

        renderer.render(scene, camera);
      }
      renderer.setAnimationLoop(animate);

      this.$el.appendChild(renderer.domElement);
    },
    handleAnimation (elements, percent) {
      elements.forEach(el => {
        // position vars
        let pathKeys = _.sortBy(Object.keys(el.anim.path));
        let numKeys = pathKeys.length - 1;
        let currentKey = 0;
        
        if (numKeys > currentKey && percent > pathKeys[currentKey + 1]) currentKey++;
        
        let nextKey = numKeys > currentKey ? currentKey + 1 : currentKey;

        let startingPos = el.anim.path[pathKeys[currentKey]];
        let endingPos = el.anim.path[pathKeys[nextKey]];

        // rotation vars
        let rotKeys = _.sortBy(Object.keys(el.anim.rot));
        let numRotKeys = rotKeys.length - 1;
        let currentRotKey = 0;
        
        if (numRotKeys > currentRotKey && percent > rotKeys[currentRotKey + 1]) currentRotKey++;
        
        let nextRotKey = numRotKeys > currentRotKey ? currentRotKey + 1 : currentRotKey;

        let startingRot = el.anim.rot[rotKeys[currentRotKey]];
        let endingRot = el.anim.rot[rotKeys[nextRotKey]];

        // interpolate animation steps
        let p = (percent - pathKeys[currentKey]) * (1 - 0) / (pathKeys[nextKey] - pathKeys[currentKey]) + 0;
        let pos = endingPos.map((num, index) => startingPos[index] + ((num - startingPos[index]) * p));

        let r = (percent - rotKeys[currentRotKey]) * (1 - 0) / (rotKeys[nextRotKey] - rotKeys[currentRotKey]) + 0;
        let rot = endingRot.map((num, index) => startingRot[index] + ((num - startingRot[index]) * r));

        pos.forEach((axis, index) => {
          if (startingPos[index] == endingPos[index]) pos[index] = endingPos[index];
        });

        rot.forEach((axis, index) => {
          if (startingRot[index] == endingRot[index]) rot[index] = endingRot[index];
        });

        el.currentPos = pos;
        el.currentRot = rot;
      });
    }
  },
  mounted () {
    this.init();

    document.addEventListener('scroll', () => {
      this.handleScroll();
    })
  }
}
</script>

<style>
canvas {
  position: sticky;
  top: 0px;
}

.animation-test-wrapper {
  width: 100vw;
  height: 300vh;
}
</style>