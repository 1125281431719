<!-- app landing page -->
<template>
  <!-- landing page wrapper el -->
  <div id="landing-wrapper">
    <!-- landing page header -->
    <div class="landing-banner">
      <h1 class="landing-banner-text">ENDLESS</h1>
      <h1 class="landing-banner-text">POSSIBILITIES</h1>
      <img id="landing-banner-image" alt="Three ProtoControl units sandwiched by the words 'ENDLESS POSSIBILITIES, FRICTIONLESS SETUP'" src="@/assets/splashrender.png" />
      <h1 class="landing-banner-text">SEAMLESS</h1>
      <h1 class="landing-banner-text">INTEGRATION</h1>
    </div>
    <div class="landing-content">
      <content-box title="Easy to use" icon="thumbs-up">
        ProtoControl is a beginner friendly product that can be set up in a matter of minutes out of the box
      </content-box>
      <content-box title="Endless possibilities" icon="infinity">
        ProtoControl brings a platform based solution that allows for infinite configurations and an ever expanding suite of top notch features
      </content-box>
      <content-box title="Professional design" icon="suitcase">
        Our Curated components have been thoroughly tested and provide reliable, crisp, and seamless integration with any device
      </content-box>
    </div>
  </div>
</template>

<!-- landing page script -->
<script>
// import vue components
import ContentBox from '@/components/ContentBox.vue';

// export vue component
export default {
  components: { ContentBox },
  name: 'LandingPage'
}
</script>

<!-- landing page css -->
<style>
/* landing ids */
#landing-banner-image {
  max-height: 30vh;
}

#landing-wrapper {
  min-height: 100vh;

  overflow: hidden;

  position: relative;
}

/* landing classes */
.landing-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  width: calc(100vw - 128px);
  padding: 64px;
}

.landing-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-device-width: 1000px) {
  #landing-banner-image {
    width: 95vw;
  }
}

</style>