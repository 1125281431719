<!-- this file contains root styling vars -->
<template>
  <!-- load Inter font -->
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap" rel="stylesheet">
  <div id="app-wrapper">
    <header-section></header-section>
    <router-view />
    <footer-section></footer-section>
  </div>
</template>

<script>
import HeaderSection from './components/HeaderSection.vue';
import FooterSection from './components/FooterSection.vue';

export default {
  name: 'App',
  components: { HeaderSection, FooterSection }
}
</script>

<style>
/* global stylings */
* {
  font-family: 'Inter';
}

/* css vars for components and pages */
:root {
  --header-height: 126.77px;
  --header-image-height: 29px;
}

/* app stylings */
body {
  background-color: var(--page-background-color);
}

#app-wrapper {
  max-width: 100vw;
  /* overflow: hidden; */
  margin: 0px;
  margin: -8px;
}
</style>