<template>
  <div id="about-us-wrapper">
    <p>
      ProtoControl began as an idea from Parker Sexton to simplify user 
      interface design with a plug-and-play control panel adaptable to 
      any system. The project came to life when Parker and four fellow 
      Electrical and Computer Engineering seniors at NC State University 
      developed the first prototype, earning them the top prize in their 
      Senior Design Competition. Building on this success, the team was 
      awarded the Miller Fellowship, enabling them to refine their concept 
      and expand its potential. ProtoControl’s public showcase further 
      solidified its impact, demonstrating the versatility and innovation 
      of its customizable hardware and intuitive software for user interface 
      design.
    </p>
    <br />
    <br />
    <p>
      Meet the founding team revolutionizing UI design:
    </p>
    <h2>
      <b>Parker Sexton - Founder and Chief Executive Officer</b>
    </h2>
    <p>
      Parker Sexton is a passionate engineer who has been creating and refining 
      his inventions since childhood. With a strong background in embedded 
      systems, Parker brings a depth of experience and a passion for innovation 
      that shapes the vision and direction of ProtoControl. 
    </p>
    <h2>
      <b>William Wood - Founder and Chief Technology Officer</b>
    </h2>
    <p>
      William Wood is a skilled software engineer with extensive experience 
      in backend software design and web app development. He is a technical 
      leader who shares ProtoControl's vision and provides crucial expertise 
      to our design process. 
    </p>
    <h2>
      <b>Abisha Fenn - Digital Hardware Design Expert</b>
    </h2>
    <p>
      Abisha Fenn is a dedicated digital design engineer who provides 
      multifaceted support for product development. Abisha expertise in 
      designing our custom hardware features has proved invaluable, and 
      she leads the work invisoning new features and prototyping ideas. 
    </p>
    <h2>
      <b>Kelly Mae Allen - System integration and Communications Expert</b>
    </h2>
    <p>
      Kelly Mae Allen is a PhD student and engineer working to bridge the 
      gaps in our features, and integrate designs at the system level. Kelly 
      Mae works to implement our custom communication protocols, and ensure 
      that all systems are compatible. 
    </p>
    <h2>
      <b>Nida Kosedagi - Analog Design Expert</b>
    </h2>
    <p>
      Nida Kosedagi is our PCB designer and analog design engineer. With a 
      knowledge of all systems, Nida is spearheading the design of our custom 
      PCBs to connect the hardware components of the Protocontrol kit together. 
      Her expertise and industry experience is vital to our hardware engineering 
      and novel device design.
    </p>
  </div>
</template>

<script>
export default{
  name: 'AboutUsView'
}
</script>

<style scoped>
#about-us-wrapper {
  width: 60vw;
  padding-left: 20vw;
  padding-right: 20vw;
}

@media screen and (max-device-width: 1000px) {
  #about-us-wrapper {
    width: calc(100vw - 32px);
    padding-left: 16px;
    padding-right: 16px;
  }
}
</style>