<template>
  <div id="documentation-wrapper">
    <div id="documentation-navigation">
      <docs-nav-item @click="segment = 'faq'">Frequently Asked Questions</docs-nav-item>
      <docs-nav-item @click="segment = 'datasheet'">Datasheet</docs-nav-item>
    </div>
    <div id="documentation-content-wrapper">
      <f-a-q-view v-if="segment == 'faq'"></f-a-q-view>
      <datasheet-view v-else-if="segment == 'datasheet'"></datasheet-view>
    </div>
  </div>
</template>

<script>
import DocsNavItem from '@/components/DocsNavItem.vue'
import FAQView from './docs/FAQView.vue'
import DatasheetView from './docs/DatasheetView.vue'
export default {
  name: 'DocumentationView',
  components: { DocsNavItem, FAQView, DatasheetView },
  data () {
    return {
      segment: 'faq'
    }
  }
}
</script>

<style>
#documentation-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

#documentation-navigation {
  width: calc(100vw / 5);
  position: absolute;
  top: 0px;
  left: 0px;

  display: flex;
  flex-direction: column;
}

#documentation-content-wrapper {
  width: calc(100vw / 2);
  height: 100vh;

  padding: 32px;
  background-color: rgba(0, 0, 0, .05);
  overflow-y: auto;
}

.docs-highlight {
  color: red;
}

.docs-image {
  width: 60%;
  margin-left: 20%;
}

@media screen and (max-device-width: 1000px) {
  #documentation-wrapper {
    flex-direction: column;
  }

  #documentation-navigation {
    width: 100vw;

    position: relative;
  }

  #documentation-content-wrapper {
    width: calc(100vw - 96px);
  }
}
</style>