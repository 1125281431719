<template>
  <div id="about-wrapper">
    <div class="about-content-block">
      <img alt="A cute twink smiling at the camera all coy and shy." src="@/assets/temp2.png" />
      <div class="about-content-text">
        <h3>The Hardware</h3>
        <p>
          ProtoControl centers around an interface panel that serves as a 
          universal interface screen. The Panel has a built in computer that 
          can create a display and register touch inputs. After connecting 
          the screen to wifi, it can be connected to the website to display 
          your latest custom layout. 
        </p>
      </div>
    </div>
    <div class="about-content-block">
      <div class="about-content-text">
        <h3>Our custom build tool</h3>
        <p>
          To build your user interface, we have created a custom drag and 
          drop build tool (link) to allow anyone to design their own layout. 
          We have curated a bank of commonly used components like buttons, 
          sliders, and output consoles to interface with any device. Each of 
          these components can be configured with individual parameters to 
          enable ultimate customization. 
        </p>
      </div>
      <img alt="A cute twink smiling at the camera all coy and shy." src="@/assets/temp3.png" />
    </div>
    <div class="about-content-block">
      <img alt="A cute twink smiling at the camera all coy and shy." src="@/assets/temp1.jpg" style="transform: rotate(-90deg)" />
      <div class="about-content-text">
        <h3>The link</h3>
        <p>
          After setting up your device, and building your layout, the last 
          step is to connect protocontrol to your device. Our simple communication 
          protocol is incredibly easy to decode, and after assigning functionality 
          to the components created in your layout, youre all set!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutView'
}
</script>

<style scoped>
#about-wrapper {
  padding-top: 12px;
  padding-bottom: 12px;
}

.about-content-block {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.about-content-block img {
  max-height: 20rem;
}

.about-content-text {
  padding: 1rem;
  max-width: calc(50vw - 2rem);

  display: flex;
  flex-direction: column;
}

@media screen and (max-device-width: 1000px) {
  .about-content-block {
    flex-direction: column;
    margin-bottom: 12px;
  }

  .about-content-block:nth-child(even) {
    flex-direction: column-reverse;
  }

  .about-content-text {
    padding: 12px;
    max-width: calc(100vw - 24px);
  }

  img {
    max-width: 100vw;
  }
}
</style>