<!-- nav button vue component -->
<template>
  <div :class="{'nav-button-wrapper': true, 'nav-button-primary': type === 'primary', 
  'nav-button-sunken': type === 'sunken', 'nav-button-raised': type === 'raised'}">
    <!-- slot renders html inbetween <nav-button> tags when used -->
    <slot class="nav-button-contents"></slot>
  </div>
</template>

<!-- nav button script -->
<script>
// export vue component
export default {
  name: "HeaderSection",
  // vue props
  // redir: the redirect url
  // type: optional prop to specify a specific style of button
  props: ["redir", "type"]
}
</script>

<!-- nav button css -->
<style scoped>
.nav-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-weight: bold;

  border-radius: 8px;
  padding: 8px;
  margin: 4px;
}

.nav-button-wrapper:hover {
  background-color: rgba(0, 0, 0, .05);
}

/* styles for different types of buttons */
.nav-button-primary, .nav-button-sunken, .nav-button-raised {
  padding-left: 16px;
  padding-right: 16px;
}

/* primary button type stylings */
.nav-button-primary {
  background-color: var(--color-brand-primary);
  border: 1px solid var(--color-brand-secondary);
}

.nav-button-primary:hover {
  background-color: var(--color-brand-secondary);
}

/* sunken button type stylings */
.nav-button-sunken {
  color: var(--color-brand-primary);
  background-color: var(--color-brand-tertiary);
  border: 1px solid var(--color-brand-tertiary);
}

.nav-button-sunken:hover {
  background-color: var(--color-brand-tertiary);
}

/* raised button type stylings */
.nav-button-raised {
  border: 2px solid var(--color-brand-secondary);
  border-bottom: 4px solid var(--color-brand-secondary);
}
</style>