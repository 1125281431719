<!-- footer section vue component -->
<template>
  <div class="footer-wrapper" style="display: none;">
    <div class="footer-social-wrapper">
      <h2><font-awesome-icon :icon="['fab', 'x-twitter']" /></h2>
      <h2><font-awesome-icon :icon="['fab', 'instagram']" /></h2>
      <h2><font-awesome-icon :icon="['fab', 'youtube']" /></h2>
      <h2><font-awesome-icon :icon="['fab', 'linkedin']" /></h2>
    </div>
    <div class="footer-column">
      <h3>Use cases</h3>
      <span>UI design</span>
      <span>UX design</span>
      <span>Wireframing</span>
      <span>Diagramming</span>
      <span>Brainstorming</span>
      <span>Online whiteboard</span>
      <span>Team collaboration</span>
    </div>
    <div class="footer-column">
      <h3>Explore</h3>
      <span>Design</span>
      <span>Prototyping</span>
      <span>Development features</span>
      <span>Design systems</span>
      <span>Collaboration features</span>
      <span>Design process</span>
      <span>FigJam</span>
    </div>
    <div class="footer-column">
      <h3>Resources</h3>
      <span>Blog</span>
      <span>Best practices</span>
      <span>Colors</span>
      <span>Color wheel</span>
      <span>Support</span>
      <span>Developers</span>
      <span>Resource library</span>
    </div>
  </div>
</template>

<!-- footer section script -->
<script>
// fa imports
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far, fab);

// export vue component
export default {
  name: "FooterSection",
  components: { FontAwesomeIcon }
}
</script>

<!-- footer section css -->
<style>
.footer-wrapper {
  width: calc(100vw - 50px);

  display: flex;
  justify-content: space-around;

  padding: 50px;
  padding-left: 25px;
  padding-right: 25px;
}

.footer-social-wrapper {
  display: flex;
  height: 100%;
}

.footer-social-wrapper > * {
  margin: 8px;
}

.footer-social-wrapper > h2 {
  cursor: pointer;
}

.footer-column {
  display: flex;
  flex-direction: column;
}

.footer-column > * {
  margin-bottom: 12px;
}

.footer-column > h3 {
  margin-bottom: 22px;
}

.footer-column > span:hover {
  cursor: pointer;
  text-decoration: underline;
}

/* mobile stylings */
@media screen and (max-device-width: 1000px) {
  .footer-wrapper {
    flex-direction: column;
  }
}
</style>